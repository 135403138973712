import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import { GOOGLE_MAPS_DEFAULT_POSITION } from 'core/constants';
import AlarmDetailsDialog from 'components/Map/LocationDetailsDialog/AlarmDetailsDialog';
import EnchantedMap from 'components/Map/EnchantedMap';
import UserControls from 'components/Map/UserControls';
import withGoogleMaps from 'components/Map/withGoogleMaps';
import { fitBounds } from 'utils/maps';
import LocationDetailsDialog from 'components/Map/LocationDetailsDialog';

import { AlarmResponse, AlertType, AmberAlarmResponse } from 'utils/api/types';

import AmberAlarmDetailsDialog from 'components/Map/LocationDetailsDialog/AmberAlarmDetailsDialog';

import ActivityMapMarkers from '../ActivityMapMarkers';
import { GlobalMapStyle } from './styles';

import { InjectedUserActivityMapProps } from './types';

@inject('activityMap')
@observer
export class UserActivityMap extends React.Component {
  mapBoundsDisposer?: IReactionDisposer;

  map?: google.maps.Map;

  componentLoaded = false;

  onMapBoundsChangedDebounced: () => void;

  constructor(props: {}) {
    super(props);

    this.onMapBoundsChangedDebounced = debounce(this.onMapBoundsChanged, 2000);
  }

  componentDidMount(): void {
    const { activityMap } = this.injected;
    this.componentLoaded = true;

    activityMap.startRefreshingMap();

    this.mapBoundsDisposer = reaction(
      () => [activityMap.isReady],
      (isReady) => {
        isReady && fitBounds(this.map, activityMap.users);
      }
    );
  }

  componentWillUnmount(): void {
    const { activityMap } = this.injected;

    this.componentLoaded = false;
    this.mapBoundsDisposer && this.mapBoundsDisposer();
    activityMap.stopRefreshingMap();
    activityMap.locationDetails.closeDetails();
  }

  onMapLoad = (map: google.maps.Map): void => {
    this.map = map;
    const { activityMap } = this.injected;

    const users = activityMap.users.length ? activityMap.users : [GOOGLE_MAPS_DEFAULT_POSITION];
    fitBounds(map, users);
  };

  onMapBoundsChanged = (): void => {
    const { activityMap } = this.injected;

    if (!activityMap.isReady || !this.map || !this.componentLoaded) {
      return;
    }
    const bounds = this.map.getBounds();

    activityMap.changeBounds(bounds ? bounds.toUrlValue() : '');
  };

  get injected(): InjectedUserActivityMapProps {
    return this.props as InjectedUserActivityMapProps;
  }

  render(): React.ReactNode {
    const { activityMap } = this.injected;

    const amberAlertDetails =
      activityMap.locationDetails.openedAlarmDetails?.alertType === AlertType.AmberAlert
        ? (activityMap.locationDetails.openedAlarmDetails as AmberAlarmResponse)
        : null;

    const redAlertDetails =
      activityMap.locationDetails.openedAlarmDetails?.alertType === AlertType.RedAlert
        ? (activityMap.locationDetails.openedAlarmDetails as AlarmResponse)
        : null;

    return (
      <>
        <GlobalMapStyle />
        <EnchantedMap onLoad={this.onMapLoad} onBoundsChanged={this.onMapBoundsChangedDebounced}>
          <UserControls searchStore={activityMap} />

          <ActivityMapMarkers />

          {activityMap.locationDetails.details && (
            <LocationDetailsDialog details={activityMap.locationDetails.details} />
          )}

          {amberAlertDetails && (
            <AmberAlarmDetailsDialog
              user={amberAlertDetails.user}
              activated={amberAlertDetails.activated}
              location={amberAlertDetails.location}
              endTime={amberAlertDetails.endTime}
            />
          )}

          {redAlertDetails && (
            <AlarmDetailsDialog
              user={redAlertDetails.user}
              activated={redAlertDetails.activated}
              location={redAlertDetails.location}
            />
          )}
        </EnchantedMap>
      </>
    );
  }
}

export default withGoogleMaps(UserActivityMap);
