import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { reverse } from 'named-urls';

import { useRouteMatch } from 'react-router-dom';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';
import Grid, { defaultFormatters } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';
import { useStore } from 'utils/hooks/store';
import { StyledCreated } from 'containers/Incidents/components/IncidentList/styles';

export const basicColumns = ([
  {
    field: 'name',
    headerName: 'Form template',
    sortable: false,
  },
  {
    field: 'rulesUpdatedAt',
    headerName: 'Last modified',
    valueFormatter: defaultFormatters.date,
    renderCell: ({ value }: { value: string }) => <StyledCreated>{formatDateTime(value)}</StyledCreated>,
  },
] as unknown) as ColumnDefinition[];

const FormToFieldMapping = (): React.ReactElement => {
  const { routing } = useStore();
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch(routes.dashboard.objectManager.details.formToFieldMapping.toString());

  const { moduleName } = match?.params as { moduleName: string };

  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    const path = reverse(routes.dashboard.objectManager.details.formToFieldMapping.toString(), {
      moduleName,
    });
    routing.push(`${path + form.row.uuid}/`);
  };

  return (
    <Record
      navBarHeight={navBarHeight}
      Header={
        <MainHeader
          title="Form-to-Field Mapping"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: getEntityTranslation[moduleName as EntityModules].plural,
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Form-to-Field Mapping',
              link: reverse(routes.dashboard.objectManager.details.formToFieldMapping.toString(), { moduleName }),
            },
          ]}
        />
      }
      Content={() => (
        <Grid
          showHeader={false}
          onRowClick={onRowClick}
          basicColumns={basicColumns}
          entity={`${moduleName} form-to-field mapping`}
          key={`form_to_field_mapping_${moduleName}`}
          dataURL={`/api/entities/${moduleName}/forms`}
          customNoRowsText="No form to field mapping has been created yet"
        />
      )}
    />
  );
};

export default FormToFieldMapping;
