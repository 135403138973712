import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CenteredCircularProgress from 'vatix-ui/lib/components/Progress/CenteredCircularProgress';

import { observer } from 'mobx-react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import { reverse } from 'named-urls';

import { Button } from 'vatix-ui/lib/components/Button';

import NotFound from 'vatix-ui/lib/components/Error/Pages/NotFound';

import { useStore } from 'utils/hooks/store';
import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { formatDateTime } from 'utils/formatters/time';
import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';

import { LastPublished } from 'containers/LayoutEditor/styles';

import { HeaderAction } from './styles';
import FormToFieldMappingSections from './components/FormToFieldMappingSections';

const FormToFieldMappingDetails = (): React.ReactElement => {
  const { formMapping, routing } = useStore();
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch(routes.dashboard.objectManager.details.formToFieldMapping.details.toString());

  const { moduleName, formId } = match?.params as { moduleName: string; formId: string };

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    formMapping.loadDetails(moduleName, formId);

    return () => {
      formMapping.cleanup();
    };
  }, [formId]);

  if (!formMapping.isLoaded) {
    return <CenteredCircularProgress aria-label="form-to-field-loading" />;
  }

  if (formMapping.error) {
    return <NotFound />;
  }

  const onPublish = async (): Promise<void> => {
    setSaving(true);
    await formMapping.saveMapping();
    setSaving(false);
  };

  const onCancel = (): void => {
    formMapping.cleanup();
    routing.push(reverse(routes.dashboard.objectManager.details.formToFieldMapping.toString(), { moduleName }));
  };

  return (
    <Record
      navBarHeight={navBarHeight}
      Header={
        <MainHeader
          title="Form-to-Field Mapping"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: getEntityTranslation[moduleName as EntityModules].plural,
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Form-to-Field Mapping',
              link: reverse(routes.dashboard.objectManager.details.formToFieldMapping.toString(), { moduleName }),
            },
            {
              label: 'Edit Mapping',
              link: reverse(routes.dashboard.objectManager.details.formToFieldMapping.details.toString(), {
                moduleName,
                formId,
              }),
            },
          ]}
          action={
            <HeaderAction>
              {formMapping.lastPublished !== undefined && (
                <LastPublished>{`Last published: ${formatDateTime(formMapping.lastPublished)}`}</LastPublished>
              )}

              <Button onClick={onCancel} variant="outlined" size="large">
                Cancel
              </Button>
              <Button onClick={onPublish} variant="contained" size="large" disabled={saving}>
                Publish
              </Button>
            </HeaderAction>
          }
        />
      }
      Content={() => <FormToFieldMappingSections form={formMapping.form} />}
    />
  );
};

export default observer(FormToFieldMappingDetails);
