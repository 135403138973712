import { styled } from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #0772e5;
  margin: 4px 0;
  background: #f5f5f5;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 8px 32px;
`;
