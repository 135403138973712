import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';
import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { EventNameChangeActivityResponse } from 'utils/api/types';

const EventName: React.FunctionComponent<{
  activity: EventNameChangeActivityResponse;
  sessionUser: UuidableName;
}> = ({ activity, sessionUser }) => {
  if ((!activity.previous_name && activity.name) || !activity.name) {
    return null;
  }

  return (
    <EventItem activity={activity} sessionUser={sessionUser} id={`activity-comment-log-${activity.name}`}>
      {` has changed the event name from "${activity.previous_name}" to "${activity.name}"`}
    </EventItem>
  );
};

export default observer(EventName);
