import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosResponse, CancelTokenSource } from 'axios';

import { action, runInAction, makeObservable } from 'mobx';

import DetailsActivities from 'vatix-ui/lib/utils/stores/DetailsActivities';

import Logger from 'vatix-ui/lib/utils/logger';

import {
  ActivityListResponse,
  ActivityType,
  ActivityType as VatixActivityType,
  PaginationParams,
  UserSearchResponse,
} from 'vatix-ui/lib/utils/api/types';

import API from 'utils/api';
import RootStore from 'stores/Root';
import { EventType } from 'utils/events/constants';
import { ActionEvent } from 'utils/events/types';
import { EntityModules } from 'core/constants';

export default class EntitiesActivities extends DetailsActivities<RootStore, typeof API> {
  entityId: string;

  entityType: EntityModules;

  constructor(rootStore: RootStore, api: typeof API, entityId: string, entityType: EntityModules) {
    super(rootStore, api);

    makeObservable(this);

    this.entityId = entityId;
    this.entityType = entityType;

    eventBus.register(this);
  }

  async loadActivitiesData(params: PaginationParams): Promise<AxiosResponse<ActivityListResponse>> {
    return this.api.loadEntityActivities(this.entityType, this.entityId, params)();
  }

  @action.bound
  async addMessage(message: string, taggedUsers?: UserSearchResponse[]): Promise<void> {
    if (this.error || !this.activities) {
      return;
    }
    try {
      const { data } = await this.api.addEntityMessage(this.entityType, this.entityId, message, taggedUsers)();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, { ...data, type: ActivityType.Message as VatixActivityType }];
        }
      });
    } catch (e) {
      Logger.error(`Invalid add message API result: ${this.entityType} ID: ${this.entityId}. Message: ${message}`, e);
    }
  }

  async doUploadFile(
    file: File,
    onUpdateProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): Promise<void> {
    try {
      const { data } = await this.api.uploadEntityFile(
        this.entityType,
        this.entityId,
        file,
        onUpdateProgress,
        cancelTokenSource
      )();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, data];
        }
      });
    } catch (e) {
      Logger.error(`Invalid add file API result: ${this.entityType} ID: ${this.entityId}.`, e);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to upload file');
    }
  }

  @action.bound
  async deleteFile(fileId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const file = this.activities.find((activity) => activity.uuid === fileId);
    if (file) {
      try {
        await this.api.removeEntityFile(this.entityType, this.entityId, fileId)();
        runInAction(() => {
          this.removeActivity(file.uuid);
        });
      } catch (e) {
        Logger.error(`Invalid delete file API result: ${this.entityType} ID: ${this.entityId}. File ID: ${fileId}`, e);
      }
    }
  }

  @action.bound
  async deleteMessage(messageId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const message = this.activities.find((activity) => activity.uuid === messageId);
    if (message) {
      try {
        await this.api.removeEntityMessage(this.entityType, this.entityId, messageId)();
        runInAction(() => {
          if (this.activities !== undefined) {
            this.activities = this.activities.filter((activity) => activity.uuid !== messageId);
          }
        });
      } catch (e) {
        Logger.error(
          `Invalid delete message API result: ${this.entityType} ID: ${this.entityId}. Message ID: ${messageId}`,
          e
        );
      }
    }
  }

  @subscribe(EventType.UpdatedSite)
  @subscribe(EventType.UpdatedEvent)
  @action
  updatedTask({ payload }: ActionEvent<{ uuid: string }>): void {
    if (this.activities !== undefined && this.entityId === payload.uuid) {
      this.activities = undefined;
      this.loadActivities();
    }
  }
}
