import { InfoBox } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import React from 'react';
import Typography from '@mui/material/Typography';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { LocationResponse, UserResponse } from 'utils/api/types';

import { StyledPaper, LocationPosition, LocationAddress } from './styles';

const AlarmDetailsDialog: React.FunctionComponent<{
  user: UserResponse;
  location: LocationResponse;
  activated: string;
}> = ({ location, user, activated }): React.ReactElement | null => {
  const position = {
    lng: Number(location.lng),
    lat: Number(location.lat),
  };

  return (
    <InfoBox
      // @ts-ignore
      position={position}
      options={{
        closeBoxURL: ``,
        enableEventPropagation: true,
        maxWidth: 300,
        boxStyle: {
          overflow: 'visible',
        },
        alignBottom: true,
        pixelOffset: new google.maps.Size(25, -10),
      }}
    >
      <StyledPaper>
        <Typography color="initial" variant="body1" display="block">
          <strong>{`${user.name} | ${formatDateTime(activated)}`}</strong>
        </Typography>

        {location.address && <LocationAddress>{location.address}</LocationAddress>}
        <LocationPosition>{`${position.lng}, ${position.lat}`}</LocationPosition>
      </StyledPaper>
    </InfoBox>
  );
};

export default observer(AlarmDetailsDialog);
