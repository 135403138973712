export enum EventType {
  ReverseGeocodedLocation = 'reverse_geocoded_location',
  CreatedRedAlert = 'created_red_alert',
  AcceptedRedAlert = 'accepted_red_alert',
  AddedAlarmMessage = 'added_alarm_message',
  AddedDeviceMessage = 'added_device_message',
  AddedUserMessage = 'added_user_message',
  AddedGroupDevice = 'added_group_device',
  AddedGroupUser = 'added_group_user',
  CompletedRedAlert = 'completed_red_alert',
  CreatedAmberAlert = 'created_amber_alert',
  CancelledAmberAlert = 'cancelled_amber_alert',
  DeletedAlarmMessage = 'deleted_alarm_message',
  DeletedDeviceMessage = 'deleted_device_message',
  DeletedUserMessage = 'deleted_user_message',
  UpdatedUser = 'updated_user',
  UpdatedProfile = 'updated_profile',
  CreatedActivity = 'created_activity',
  UpdatedActivity = 'updated_activity',
  DeletedUsers = 'deleted_users',
  LoggedIn = 'logged_in',
  LoggedOut = 'logged_out',
  CreatedUser = 'created_user',
  UpdatedGroup = 'updated_group',
  DeletedGroups = 'deleted_groups',
  DeletedGroupUsers = 'deleted_group_users',
  UnassignedDevices = 'unassigned_device',
  DeletedGroupDevices = 'deleted_group_devices',
  UpdatedAmberAlert = 'updated_amber_alert',
  UpdatedDevice = 'updated_device',
  UpdatedEscalationProfile = 'updated_escalation_profile',
  UpdateCustomIncidentType = 'update_custom_incident_type',
  AddedFile = 'added_file',
  AddedMessage = 'added_message',
  DeletedFile = 'deleted_file',
  UpdatedTask = 'updated_task',
  CreatedTask = 'created_task',
  DeletedMessage = 'deleted_message',
  DeletedTask = 'deleted_task',
  CreatedTaskAnswerTask = 'created_task_answer_task',
  UpdatedSite = 'updated_site',
  UpdatedEvent = 'updated_event',
}

export const BROADCAST_CHANNEL_NAME = 'tabs-sync';
