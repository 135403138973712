import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'vatix-ui/lib/components/Button';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import CustomModal from 'components/CustomModal';

import { useStore } from 'utils/hooks/store';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { DetailsRowContainer, TitleText } from './styles';
import { EntitiesStores } from '../types';

const AddEntityModal: React.FunctionComponent<{
  onClose: (created: boolean) => void;
  store: EntitiesStores;
  openModal: boolean;
}> = ({ onClose, store, openModal }): React.ReactElement => {
  const { notification } = useStore();

  const entityTranslation = getEntityTranslation[store.entityType];

  const [name, setName] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const handleCreate = async (): Promise<void> => {
    setSubmitting(true);
    const created = await store.create(name);

    if (created) {
      notification.enqueueSuccessSnackbar(`${entityTranslation.singular} created successfully`);
      onClose(true);
      setName('');
    } else {
      notification.enqueueErrorSnackbar(
        `The ${entityTranslation.singular} could not be created. Something went wrong.`
      );
    }
    setSubmitting(false);
  };

  const onCloseModal = (): void => onClose(false);

  return (
    <CustomModal
      openModal={openModal}
      onClose={onCloseModal}
      modalTitle={`Create New ${entityTranslation.singular}`}
      cancelButton={
        <Button id="cancel-button" onClick={onCloseModal} variant="outlined" size="large" data-testid="cancelButton">
          Cancel
        </Button>
      }
      submitButton={
        <Button
          id={`create-${store.entityType}-button`}
          onClick={handleCreate}
          disabled={name === '' || submitting}
          variant="contained"
          size="large"
          data-testid="addButton"
        >
          Create
        </Button>
      }
    >
      <DetailsRowContainer>
        <TitleText>{entityTranslation.singular} Name</TitleText>
        <CustomTextInputField
          value={name}
          onChange={(e) => setName((e as unknown) as string)}
          description="Name"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCreate();
            }
          }}
        />
      </DetailsRowContainer>
    </CustomModal>
  );
};

export default observer(AddEntityModal);
