import { include } from 'named-urls';

export default {
  dashboard: {
    home: '/home/',
    notifications: '/inbox/',
    tasks: include('/actions/', {
      all: 'all/',
      my: 'my-tasks/',
      details: ':taskId/',
    }),
    incidents: include('/incidents/', {
      report: 'new-submission/',
      details: ':incidentId/',
      relatedIncidents: 'related/incidents/:entityId/',
      relatedActions: 'related/actions/:entityId/',
      relatedAudits: 'related/audits/:entityId/',
    }),
    alarms: include('/lone-working/', {
      red: 'alarms/',
      amber: 'timed-alerts/',
      escalationProfiles: include('alarm-response-profiles/', {
        main: '',
        details: ':escalationProfileId/',
      }),
      details: 'alarms/:alarmId/',
      manage: 'manage/',
      devices: include('devices/', {
        details: ':deviceId/',
      }),
      activityMap: 'map/',
      reports: 'usage/',
    }),
    audits: include('/audits/', {
      forms: include('forms/', { details: ':formId/' }),
      templates: include('templates/', { details: ':templateId/' }),
    }),
    admin: include('/admin/', {
      users: include('users/', {
        details: ':userId/',
      }),
      teams: include('teams/', {
        new: 'new/',
        details: ':teamId/',
      }),
      companySettings: 'company-settings/',
      licenses: 'licenses/',
    }),
    profileSettings: include('/settings/', {
      security: 'security/',
      notifications: 'notifications/',
    }),
    moreApps: '/more/',
    analytics: include('/analytics/', {
      details: ':dashboardId/',
    }),
    disableFeature: include('/disable-feature/', {
      details: ':featureId/',
    }),
    sites: include('/sites/', {
      details: ':entityId/',
      relatedIncidents: 'related/incidents/:entityId/',
      relatedActions: 'related/actions/:entityId/',
      relatedAudits: 'related/audits/:entityId/',
      relatedSites: 'related/sites/:entityId/',
      relatedAssets: 'related/assets/:entityId/',
      relatedEvents: 'related/events/:entityId/',
      relatedSuppliers: 'related/suppliers/:entityId/',
    }),
    assets: include('/assets/', {
      details: ':entityId/',
      relatedIncidents: 'related/incidents/:entityId/',
      relatedActions: 'related/actions/:entityId/',
      relatedAudits: 'related/audits/:entityId/',
      relatedSites: 'related/sites/:entityId/',
      relatedAssets: 'related/assets/:entityId/',
      relatedEvents: 'related/events/:entityId/',
      relatedSuppliers: 'related/suppliers/:entityId/',
    }),
    risks: include('/risks/', {
      details: ':entityId/',
      relatedIncidents: 'related/incidents/:entityId/',
      relatedActions: 'related/actions/:entityId/',
      relatedAudits: 'related/audits/:entityId/',
      relatedSites: 'related/sites/:entityId/',
      relatedAssets: 'related/assets/:entityId/',
      relatedEvents: 'related/events/:entityId/',
      relatedSuppliers: 'related/suppliers/:entityId/',
    }),
    suppliers: include('/suppliers/', {
      details: ':entityId/',
      relatedIncidents: 'related/incidents/:entityId/',
      relatedActions: 'related/actions/:entityId/',
      relatedSites: 'related/sites/:entityId/',
      relatedAssets: 'related/assets/:entityId/',
      relatedEvents: 'related/events/:entityId/',
      relatedSuppliers: 'related/suppliers/:entityId/',
      relatedAudits: 'related/audits/:entityId/',
    }),
    events: include('/events/', {
      details: ':entityId/',
      relatedIncidents: 'related/incidents/:entityId/',
      relatedActions: 'related/actions/:entityId/',
      relatedAudits: 'related/audits/:entityId/',
      relatedSites: 'related/sites/:entityId/',
      relatedAssets: 'related/assets/:entityId/',
      relatedEvents: 'related/events/:entityId/',
      relatedSuppliers: 'related/suppliers/:entityId/',
    }),
    objectManager: include('/object-manager/', {
      details: include(':moduleName/', {
        fieldManager: 'field-manager/',
        layoutEditor: 'layout-editor/',
        formBuilder: include('form-builder/', { details: ':formId/' }),
        formToFieldMapping: include('form-to-field-mapping/', { details: ':formId/' }),
      }),
    }),
  },
  login: '/login/',
  twoFactorAuthSetup: '/2fa/setup/',
  publicSubmission: include('/publicSubmission/', { details: ':organisationId/' }),
  signUp: include('/signup/', {
    confirm: 'confirm/:token/',
  }),
  passwordReset: include('/password/reset/', {
    change: ':uuid/:token/',
  }),
};
