import React from 'react';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import AddIcon from '@mui/icons-material/Add';

import { Button } from 'vatix-ui/lib/components/Button';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { MainContainer } from './styles';
import SortableItem from './SortableItem';
import { EditDropDownOptionsProps } from './types';

const EditDropDownOptions: React.FC<EditDropDownOptionsProps> = ({
  properties,
  onSettingProperties,
  sectionId,
  fieldId,
}) => {
  const { formBuilder } = useStore();
  const options = properties?.enum || [];

  const updateProperties = (newOptions: { key: string; value: string }[]): void => {
    onSettingProperties({ enum: newOptions });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = options.findIndex((item) => item.key === active.id);
      const newIndex = options.findIndex((item) => item.key === over.id);
      const newData = arrayMove(options, oldIndex, newIndex);

      onSettingProperties({ enum: newData });
    }
  };

  const handleAddNewOption = (): void => {
    const newOption: { key: string; value: string } = {
      key: formBuilder.getNextContainerId(),
      value: `Untitled ${options.length + 1}`,
    };
    formBuilder.setDropdownOptionInEdit(newOption.key);
    onSettingProperties({ enum: [...options, newOption] });
  };

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  return (
    <MainContainer>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={options.map((item) => item.key)} strategy={verticalListSortingStrategy}>
          {options.length > 0 &&
            options.map((item) => (
              <SortableItem
                id={item.key}
                key={item.key}
                item={item}
                options={options}
                setOptions={updateProperties}
                // if there is only one option, we should not allow the user to remove it
                isLastOption={options.length === 1}
                sectionId={sectionId}
                fieldId={fieldId}
              />
            ))}
        </SortableContext>
      </DndContext>
      <Button
        startIcon={<AddIcon />}
        value="text"
        onClick={handleAddNewOption}
        color="primary"
        size="small"
        style={{ width: 'fit-content' }}
      >
        Add option
      </Button>
    </MainContainer>
  );
};

export default observer(EditDropDownOptions);
