import { reverse } from 'named-urls';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';
import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import { BaseProductType } from './types';

export const createEntitySideBarItem = (
  Icon: JSX.Element,
  ActiveIcon: JSX.Element,
  moduleName: EntityModules
): BaseProductType & { subItems?: { name: string; to: string; isSelected: boolean }[] | undefined } => {
  const { routing } = useStore();
  const formBuilder = {
    name: 'Forms',
    to: reverse(routes.dashboard.objectManager.details.formBuilder.toString(), { moduleName }),
    isSelected: routing.location.pathname.startsWith(
      reverse(routes.dashboard.objectManager.details.formBuilder.toString(), { moduleName })
    ),
  };

  const formToFieldMapping = {
    name: 'Form-to-Field Mapping',
    to: reverse(routes.dashboard.objectManager.details.formToFieldMapping.toString(), { moduleName }),
    isSelected: routing.location.pathname.startsWith(
      reverse(routes.dashboard.objectManager.details.formToFieldMapping.toString(), { moduleName })
    ),
  };

  const subItems = [
    {
      name: 'Field Manager',
      to: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
      isSelected: routing.location.pathname.startsWith(
        reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName })
      ),
    },
    {
      name: 'Layout Editor',
      to: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName }),
      isSelected: routing.location.pathname.startsWith(
        reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName })
      ),
    },
  ];

  if (moduleName === EntityModules.Events) {
    subItems.push(formBuilder);
    subItems.push(formToFieldMapping);
  }

  return {
    name: getEntityTranslation[moduleName].plural,
    Icon,
    ActiveIcon,
    to: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName }),
    isSelected: routing.location.pathname.startsWith(routes.dashboard.objectManager.details.fieldManager.toString()),
    subItems,
  };
};
