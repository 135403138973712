import React from 'react';

import { ClickAwayListener, IconButton } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { StyledSelect } from 'components/Input/styles';

import { MainRow, Item, StyledMenuItem } from './styles';
import { DropDownConditionsProps } from './types';

const DropDownConditions = ({
  sectionId,
  fieldId,
  item,
  onRemoveOption,
  isLastOption,
  handleClickAway,
  children,
}: DropDownConditionsProps): React.ReactElement => {
  const {
    formBuilder: {
      getAllSectionTitlesAndKeys,
      formAction: { updateFormActions, getActionForQuestionAndValue },
    },
  } = useStore();

  const containers = getAllSectionTitlesAndKeys(sectionId).filter(({ key }) => key !== sectionId);

  const [section, setSection] = React.useState(getActionForQuestionAndValue(sectionId, fieldId, item.key));

  const updateLogicForQuestion = (sec: string): void => {
    setSection(sec);
    updateFormActions(sectionId, fieldId, item.key, sec);
  };

  return (
    <MainRow>
      <ClickAwayListener touchEvent="onTouchStart" mouseEvent="onMouseDown" onClickAway={handleClickAway}>
        <Item>
          {children}
          {!isLastOption && (
            <IconButton onClick={onRemoveOption}>
              <ClearIcon htmlColor="rgba(0, 0, 0, 0.54)" />
            </IconButton>
          )}
        </Item>
      </ClickAwayListener>
      <StyledSelect
        value={section}
        onChange={({ target: { value } }) => {
          updateLogicForQuestion(value as string);
        }}
        style={{ width: '50%' }}
        sx={{
          maxHeight: '40px',
          '.MuiInputBase-input': {
            fontSize: '12px !important',
          },
        }}
      >
        {containers.map(({ key, title }) => (
          <StyledMenuItem key={key} value={key}>
            Go to section ({title})
          </StyledMenuItem>
        ))}
        <StyledMenuItem value="next">Continue to next section</StyledMenuItem>
        <StyledMenuItem value="submit">Submit form</StyledMenuItem>
      </StyledSelect>
    </MainRow>
  );
};

export default observer(DropDownConditions);
