import { Button } from 'vatix-ui/lib/components/Button';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { EntityFormType } from 'utils/api/types';
import { FIELD_MANAGER_HELP_URL } from 'core/constants';

import { StyledTextField } from 'components/Input/styles';

import { FormTemplateTitle, MainContainer } from './styles';
import RuleSection from './RuleSection';

const FormToFieldMappingSections: React.FC<{
  form?: EntityFormType;
}> = ({ form }): React.ReactElement => {
  const redirectToHelpCenter = (): void => {
    // TODO: add link to help center
    window.open(FIELD_MANAGER_HELP_URL, '_blank');
  };

  return (
    <>
      <Button
        onClick={redirectToHelpCenter}
        variant="text"
        size="large"
        endIcon={<HelpOutlineOutlinedIcon />}
        style={{
          top: '50%',
          right: '0px',
          position: 'fixed',
          marginRight: '4px',
          background: 'rgba(7, 114, 229, 0.08)',
          transform: 'translateY(-50%) rotate(-90deg)',
        }}
      >
        Help Centre
      </Button>
      <MainContainer>
        <FormTemplateTitle>Form template</FormTemplateTitle>
        <StyledTextField
          disabled
          fullWidth
          value={form?.name || ''}
          style={{ background: '#fff', borderRadius: '4px' }}
        />
        {form?.form?.order.map((section) => {
          const { order, properties, title, description } = form.form.properties[section];
          return (
            <RuleSection key={section} order={order} properties={properties} title={title} description={description} />
          );
        })}
      </MainContainer>
    </>
  );
};

export default FormToFieldMappingSections;
