import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';
import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { EventOwnerChangeActivityResponse } from 'utils/api/types';

const EventOwner: React.FunctionComponent<{
  activity: EventOwnerChangeActivityResponse;
  sessionUser: UuidableName;
}> = ({ activity, sessionUser }) => {
  if (activity.previous_owner && activity.owner) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-reassigned">
        has changed the event owner from {activity.previous_owner.name} to {activity.owner.name}
      </EventItem>
    );
  }
  if (activity.owner) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-assigned">
        has assigned {activity.owner.name} as the owner of the event
      </EventItem>
    );
  }
  if (activity.previous_owner && activity.owner === null) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-unassigned">
        unassigned event from {activity.previous_owner.name}
      </EventItem>
    );
  }
  return <></>;
};

export default observer(EventOwner);
