import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { SiteManagerChangeActivityProps } from './types';

const SiteManagerChangeActivity: React.FunctionComponent<SiteManagerChangeActivityProps> = ({
  activity,
  sessionUser,
}) => {
  if (activity.manager) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-site-manager-change">
        changed site manager to {activity.manager.name}
      </EventItem>
    );
  }
  if (activity.previous_manager && activity.manager === null) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-site-manager-removed">
        removed site manager {activity.previous_manager.name}
      </EventItem>
    );
  }
  return <></>;
};

export default observer(SiteManagerChangeActivity);
