import styled from 'styled-components';

export const Container = styled.div<{ $height?: number }>`
  display: flex;
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: #fff;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  height: ${({ $height }) => $height || 32}px;
`;

export const EnumOption = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
`;
