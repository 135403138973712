import React from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';

import { observer } from 'mobx-react';

import { ClickAwayListener, Divider, IconButton, MenuItem } from '@mui/material';

import { ExpandLess, ExpandMore, MoreVert } from '@mui/icons-material';

import { useStore } from 'utils/hooks/store';
import { StyledTextField } from 'components/Input/styles';

import { Header, SectionInformation, StyledPopover } from '../styles';

const TextFiledStyle = {
  '& .MuiInputBase-root': {
    maxHeight: '36px',
    paddingTop: '6px',
    paddingBottom: '6px',
    background: 'white',
  },
};

type SectionHeaderProps = {
  id: UniqueIdentifier;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ id, setExpanded, expanded }): React.ReactElement => {
  const { formBuilder } = useStore();
  const { title, description } = formBuilder.sectionLogic.getSectionTitleAndDescription(id as string);

  const [data, setData] = React.useState({
    title,
    description,
  });

  const [editing, setEditing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  const onClickThreeDots = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const onClickAway = (): void => {
    // update only the title and description of edited section
    if (editing) {
      setEditing(false);
      formBuilder.sectionLogic.updateSectionDetails(id as string, data.title, data.description);
    }
  };

  const onEdit = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setEditing(true);
  };

  const addQuestion = (): void => {
    formBuilder.sectionLogic.addQuestionToSection(id as string);
    setAnchorEl(null);
  };

  const onDelete = (): void => {
    formBuilder.sectionLogic.removeSection(id as string);
    setAnchorEl(null);
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Header onClick={handleExpandClick} role="button" tabIndex={0} $expanded={expanded} className="sectionHeader">
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', width: '100%' }}>
            {expanded ? (
              <ExpandLess aria-labelledby="show less" fillOpacity="0.54" style={{ width: '16px', height: '16px' }} />
            ) : (
              <ExpandMore aria-labelledby="show more" fillOpacity="0.54" style={{ width: '16px', height: '16px' }} />
            )}
            <SectionInformation onClick={onEdit}>
              {editing ? (
                <>
                  <StyledTextField
                    sx={TextFiledStyle}
                    value={data.title}
                    size="small"
                    onChange={(e) => setData({ ...data, title: e.target.value })}
                    fullWidth
                  />
                  <StyledTextField
                    placeholder="Description (Optional)"
                    sx={TextFiledStyle}
                    value={data.description}
                    size="small"
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <h1 style={{ margin: 0 }}>{title}</h1>
                  {description && description.length > 0 && <p style={{ margin: 0 }}>{description}</p>}
                </>
              )}
            </SectionInformation>
          </div>
          <IconButton onClick={onClickThreeDots}>
            <MoreVert />
          </IconButton>
        </Header>
      </ClickAwayListener>
      <StyledPopover
        disableScrollLock
        id="popover-remove-rename-section"
        data-testid="popover-remove-rename-section"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem id="delete-section-button" onClick={onDelete}>
          Delete
        </MenuItem>
        <MenuItem
          id="duplicate-section-button"
          disabled
          onClick={() => {
            // TODO: onDuplicate
          }}
        >
          Duplicate
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem id="add-question-button" onClick={addQuestion}>
          Add question
        </MenuItem>
      </StyledPopover>
    </>
  );
};

export default observer(SectionHeader);
